import React, { Component } from 'react';
import { array, arrayOf, bool, func, number, object, shape, string } from 'prop-types';
import pickBy from 'lodash/pickBy';
import classNames from 'classnames';

import appSettings from '../../../config/settings';
import { useConfiguration } from '../../../context/configurationContext';
import { useRouteConfiguration } from '../../../context/routeConfigurationContext';

import { FormattedMessage, intlShape, useIntl } from '../../../util/reactIntl';
import { isMainSearchTypeKeywords, isOriginInUse } from '../../../util/search';
import { parse, stringify } from '../../../util/urlHelpers';
import { CREATOR_SEARCH_ID, TYPE_UPLOAD_SUCCESS } from '../../../constants';
import { createResourceLocatorString, matchPathname, pathByRouteName } from '../../../util/routes';
import { AUDIENCE_USER_TYPE, CREATOR_USER_TYPE, FILM_PRODUCTS, propTypes, PURCHASES_TAB, SERIES_PRODUCTS, WISHLIST_TAB } from '../../../util/types';
import {
  Button,
  LimitedAccessBanner,
  LinkedLogo,
  Modal,
  ModalMissingInformation,
  Menu,
  MenuLabel,
  MenuContent,
  MenuItem,
  Avatar,
  NamedLink,
  InlineTextButton
} from '../../../components';
import IconCollection from '../../../components/IconCollection/IconCollection';

import MenuIcon from './MenuIcon';
import SearchIcon from './SearchIcon';
import TopbarSearchForm from './TopbarSearchForm/TopbarSearchForm';
import TopbarMobileMenu from './TopbarMobileMenu/TopbarMobileMenu';
import TopbarDesktop from './TopbarDesktop/TopbarDesktop';

import { LISTING_SEARCH_ID } from '../../../constants';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import css from './Topbar.module.css';
import CustomLinksMenu from './TopbarDesktop/CustomLinksMenu/CustomLinksMenu';
import { useDispatch, useSelector } from 'react-redux';
import { clearNotification } from '../../../ducks/user.duck';

const MAX_MOBILE_SCREEN_WIDTH = 1024;

const redirectToURLWithModalState = (props, modalStateParam) => {
  const { history, location } = props;
  const { pathname, search, state } = location;
  const searchString = `?${stringify({ [modalStateParam]: 'open', ...parse(search) })}`;
  history.push(`${pathname}${searchString}`, state);
};

const redirectToURLWithoutModalState = (props, modalStateParam) => {
  const { history, location } = props;
  const { pathname, search, state } = location;
  const queryParams = pickBy(parse(search), (v, k) => {
    return k !== modalStateParam;
  });
  const stringified = stringify(queryParams);
  const searchString = stringified ? `?${stringified}` : '';
  history.push(`${pathname}${searchString}`, state);
};

const isPrimary = o => o.group === 'primary';
const isSecondary = o => o.group === 'secondary';
const compareGroups = (a, b) => {
  const isAHigherGroupThanB = isPrimary(a) && isSecondary(b);
  const isALesserGroupThanB = isSecondary(a) && isPrimary(b);
  // Note: sort order is stable in JS
  return isAHigherGroupThanB ? -1 : isALesserGroupThanB ? 1 : 0;
};
// Returns links in order where primary links are returned first
const sortCustomLinks = customLinks => {
  const links = Array.isArray(customLinks) ? customLinks : [];
  return links.sort(compareGroups);
};

// Resolves in-app links against route configuration
const getResolvedCustomLinks = (customLinks, routeConfiguration) => {
  const links = Array.isArray(customLinks) ? customLinks : [];
  return links.map(linkConfig => {
    const { type, href } = linkConfig;
    const isInternalLink = type === 'internal' || href.charAt(0) === '/';
    if (isInternalLink) {
      // Internal link
      const testURL = new URL('http://my.marketplace.com' + href);
      const matchedRoutes = matchPathname(testURL.pathname, routeConfiguration);
      if (matchedRoutes.length > 0) {
        const found = matchedRoutes[0];
        const to = { search: testURL.search, hash: testURL.hash };
        return {
          ...linkConfig,
          route: {
            name: found.route?.name,
            params: found.params,
            to,
          },
        };
      }
    }
    return linkConfig;
  });
};

const isCMSPage = found =>
  found.route?.name === 'CMSPage' ? `CMSPage:${found.params?.pageId}` : null;
const isInboxPage = found =>
  found.route?.name === 'InboxPage' ? `InboxPage:${found.params?.tab}` : null;
// Find the name of the current route/pathname.
// It's used as handle for currentPage check.
const getResolvedCurrentPage = (location, routeConfiguration) => {
  const matchedRoutes = matchPathname(location.pathname, routeConfiguration);
  if (matchedRoutes.length > 0) {
    const found = matchedRoutes[0];
    const cmsPageName = isCMSPage(found);
    const inboxPageName = isInboxPage(found);
    return cmsPageName ? cmsPageName : inboxPageName ? inboxPageName : `${found.route?.name}`;
  }
};

const GenericError = props => {
  const { show } = props;
  const classes = classNames(css.genericError, {
    [css.genericErrorVisible]: show,
  });
  return (
    <div className={classes}>
      <div className={css.genericErrorContent}>
        <p className={css.genericErrorText}>
          <FormattedMessage id="Topbar.genericError" />
        </p>
      </div>
    </div>
  );
};

GenericError.propTypes = {
  show: bool.isRequired,
};


const ProfileMenu = ({ currentPage, currentUser, onLogout }) => {
  const currentPageClass = page => {
    const isAccountSettingsPage =
      page === 'AccountSettingsPage' && ACCOUNT_SETTINGS_PAGES.includes(currentPage);
    return currentPage === page || isAccountSettingsPage ? css.currentPage : null;
  };

  const { publicData, displayName } = currentUser?.attributes?.profile || {};
  const { userName } = publicData || {};

  return (
    <Menu>
      <MenuLabel className={css.profileMenuLabel} isOpenClassName={css.profileMenuIsOpen}>
        <div className={css.loggedInMenu}>
          {/* <Avatar className={css.avatar} user={currentUser} disableProfileLink /> */}
          <IconCollection icon="menu" />
        </div>
      </MenuLabel>
      <MenuContent className={css.profileMenuContent}>
        <MenuItem key="ProfileSettingsPage">
          <NamedLink
            className={classNames(css.menuLink, currentPageClass('ProfileSettingsPage'))}
            name="ProfileSettingsPage"
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.profileSettingsLink" />
          </NamedLink>
        </MenuItem>
        <MenuItem key="ProfilePage">
          {currentUser?.id?.uuid ? <NamedLink
            className={classNames(css.menuLink, currentPageClass('ProfilePage'))}
            name="ProfilePage"
            params={{ id: currentUser?.id?.uuid, username: userName || displayName }}
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.profilePageLink" />
          </NamedLink> : null}
        </MenuItem>
        <MenuItem key="ManageListingsPage">
          <NamedLink
            className={classNames(css.menuLink, currentPageClass('ManageListingsPage'))}
            name="ManageListingsPage"
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.yourListingsLink" />
          </NamedLink>
        </MenuItem>
        <MenuItem key="InboxPage">
          <NamedLink
            className={classNames(css.menuLink, currentPageClass('InboxPage'))}
            name="InboxPage"
            params={{ tab: 'sales' }}
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.inboxLink" />
          </NamedLink>
        </MenuItem>
        {/* Films */}
        <MenuItem key="searchPage-films">
          <div className={css.divider} />
          <NamedLink
            className={classNames(css.menuLink, currentPageClass('searchPage-films'))}
            name="SearchPageGenre"
            params={{ searchId: LISTING_SEARCH_ID, genre: FILM_PRODUCTS, }}
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.filmPageLink" />
          </NamedLink>
        </MenuItem>
        {/* Series */}
        <MenuItem key="searchPage-series">
          <NamedLink
            className={classNames(css.menuLink, currentPageClass('searchPage-series'))}
            name="SearchPageGenre"
            params={{ searchId: LISTING_SEARCH_ID, genre: SERIES_PRODUCTS, }}
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.seriesPageLink" />
          </NamedLink>
        </MenuItem>
        {/* Creators */}
        <MenuItem key="SearchPage">
          <NamedLink
            className={classNames(css.menuLink, currentPageClass('SearchPage'))}
            name="SearchPage"
            params={{ searchId: CREATOR_SEARCH_ID }}
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.creatorPageLink" />
          </NamedLink>
        </MenuItem>
        <MenuItem key="MyLibrary">
          <div className={css.divider} />
          <NamedLink
            className={classNames(css.menuLink, currentPageClass('MyLibrary'))}
            name="MyLibraryPage"
            params={{ tab: PURCHASES_TAB }}
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.myLibraryLink" />
          </NamedLink>
        </MenuItem>
        <MenuItem key="WishlistPage">
          <NamedLink
            className={classNames(css.menuLink, currentPageClass('MyLibrary'))}
            name="MyLibraryPage"
            params={{ tab: WISHLIST_TAB }}
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.wishlistLink" />
          </NamedLink>
        </MenuItem>
        <MenuItem key="Settings">
          <div className={css.divider} />
          <NamedLink
            className={classNames(css.menuLink, currentPageClass('Settings'))}
            name="ProfileSettingsPage"
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.settingsLink" />
          </NamedLink>
        </MenuItem>
        <MenuItem key="logout">
          <InlineTextButton rootClassName={css.logoutButton} onClick={onLogout}>
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.logout" />
          </InlineTextButton>
        </MenuItem>
      </MenuContent>
    </Menu>
  );
};

const AudienceProfileMenu = ({ currentPage, currentUser, onLogout }) => {
  const currentPageClass = page => {
    const isAccountSettingsPage =
      page === 'AccountSettingsPage' && ACCOUNT_SETTINGS_PAGES.includes(currentPage);
    return currentPage === page || isAccountSettingsPage ? css.currentPage : null;
  };

  return (
    <Menu>
      <MenuLabel className={css.profileMenuLabel} isOpenClassName={css.profileMenuIsOpen}>
        <div className={css.loggedInMenu}>
          {/* <Avatar className={css.avatar} user={currentUser} disableProfileLink /> */}
          <IconCollection icon="menu" />
        </div>
      </MenuLabel>
      <MenuContent className={css.profileMenuContent}>
        <MenuItem key="SearchPageFilms">
          <NamedLink
            className={classNames(css.menuLink, currentPageClass('SearchPageFilms'))}
            name="SearchPageGenre"
            params={{ searchId: LISTING_SEARCH_ID, genre: FILM_PRODUCTS, }}
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.filmPageLink" />
          </NamedLink>
        </MenuItem>
        <MenuItem key="SearchPageOldSeries">
          <NamedLink
            className={classNames(css.menuLink, currentPageClass('SearchPageOldSeries'))}
            name="SearchPageGenre"
            params={{ searchId: LISTING_SEARCH_ID, genre: SERIES_PRODUCTS, }}
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.seriesPageLink" />
          </NamedLink>
        </MenuItem>
        <MenuItem key="SearchPage">
          <NamedLink
            className={classNames(css.menuLink, currentPageClass('SearchPage'))}
            name="SearchPage"
            params={{ searchId: CREATOR_SEARCH_ID }}
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.creatorPageLink" />
          </NamedLink>
        </MenuItem>
        <MenuItem key="MyLibrary">
          <div className={css.divider} />
          <NamedLink
            className={classNames(css.menuLink, currentPageClass('MyLibrary'))}
            name="MyLibraryPage"
            params={{ tab: PURCHASES_TAB }}
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.myLibraryLink" />
          </NamedLink>
        </MenuItem>
        <MenuItem key="WishlistPage">
          <NamedLink
            className={classNames(css.menuLink, currentPageClass('WishlistPage'))}
            name="MyLibraryPage"
            params={{ tab: WISHLIST_TAB }}
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.wishlistLink" />
          </NamedLink>
        </MenuItem>
        <MenuItem key="beComeCreator">
          <NamedLink
            className={classNames(css.menuLink, currentPageClass('beComeCreator'))}
            name="AudienceProfileSettingsPage"
            params={{ userType: AUDIENCE_USER_TYPE }}
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.beComeCreatorLink" />
          </NamedLink>
        </MenuItem>
        <MenuItem key="ProfileSettingsPage">
          <div className={css.divider} />
          <NamedLink
            className={classNames(css.menuLink, currentPageClass('ProfileSettingsPage'))}
            name="ProfileSettingsPage"
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.settingsLink" />
          </NamedLink>
        </MenuItem>
        <MenuItem key="logout">
          <InlineTextButton rootClassName={css.logoutButton} onClick={onLogout}>
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.logout" />
          </InlineTextButton>
        </MenuItem>
      </MenuContent>
    </Menu>
  );
};

const NotSignedInMenu = ({ currentPage, currentUser, onLogout }) => {
  const currentPageClass = page => {
    const isAccountSettingsPage =
      page === 'AccountSettingsPage' && ACCOUNT_SETTINGS_PAGES.includes(currentPage);
    return currentPage === page || isAccountSettingsPage ? css.currentPage : null;
  };

  return (
    <Menu>
      <MenuLabel className={css.profileMenuLabel} isOpenClassName={css.profileMenuIsOpen}>
        <IconCollection icon="menu" />
      </MenuLabel>
      <MenuContent className={css.profileMenuContent}>
        <MenuItem key="SearchPageFilm">
          <NamedLink
            className={classNames(css.menuLink, currentPageClass('SearchPageFilm'))}
            name="SearchPageGenre"
            params={{ searchId: LISTING_SEARCH_ID, genre: FILM_PRODUCTS, }}
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.filmPageLink" />
          </NamedLink>
        </MenuItem>
        <MenuItem key="SearchPageSeries">
          <NamedLink
            className={classNames(css.menuLink, currentPageClass('SearchPageSeries'))}
            name="SearchPageGenre"
            params={{ searchId: LISTING_SEARCH_ID, genre: SERIES_PRODUCTS, }}
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.seriesPageLink" />
          </NamedLink>
        </MenuItem>
        <MenuItem key="SearchPage">
          <NamedLink
            className={classNames(css.menuLink, currentPageClass('SearchPage'))}
            name="SearchPage"
            params={{ searchId: CREATOR_SEARCH_ID }}
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.creatorPageLink" />
          </NamedLink>
        </MenuItem>
        <MenuItem key="BeComeCreator">
          <div className={css.divider} />
          <NamedLink
            className={classNames(css.menuLink, currentPageClass('BeComeCreator'))}
            name="AudienceProfileSettingsPage"
            params={{ userType: AUDIENCE_USER_TYPE }}
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.beComeCreatorLink" />
          </NamedLink>
        </MenuItem>
        <MenuItem key="logout">
          <div className={css.divider} />
          <NamedLink
            className={classNames(css.menuLink, currentPageClass('SignupForUserTypePage'))}
            name="SignupForUserTypePage"
            params={{ userType: CREATOR_USER_TYPE }}
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="Join Now" />
          </NamedLink>
          <NamedLink
            className={classNames(css.menuLink, currentPageClass('ManageListingsPage'))}
            name="ManageListingsPage"
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="Topbar.login" />
          </NamedLink>
        </MenuItem>
      </MenuContent>
    </Menu>
  );
};

// Notification Component
const Notifications = (props) => {
  const { uploadNotifications } = props;
  const dispatch = useDispatch();

  // Function to handle the close button click
  const handleCloseNotification = (id) => {
    dispatch(clearNotification(id)); // Dispatch action to remove the notification
  };

  if (!uploadNotifications.length) return null;

  return (
    <div className={css.notificationContainer}>
      {uploadNotifications.map((notification) => (
        <div
          key={notification.id}
          className={`${css.notification} ${notification.type === TYPE_UPLOAD_SUCCESS ? css.success : css.error}`}
        >
          <p style={{ margin: 0 }}>{notification.message}</p>
          <button className={css.closeButton} onClick={() => handleCloseNotification(notification.id)}>
            <IconCollection icon="icon-close" />
          </button>
        </div>
      ))}
    </div>
  );
};

class TopbarComponent extends Component {
  constructor(props) {
    super(props);
    this.handleMobileMenuOpen = this.handleMobileMenuOpen.bind(this);
    this.handleMobileMenuClose = this.handleMobileMenuClose.bind(this);
    this.handleMobileSearchOpen = this.handleMobileSearchOpen.bind(this);
    this.handleMobileSearchClose = this.handleMobileSearchClose.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleLogout = this.handleLogout.bind(this);
  }

  handleMobileMenuOpen() {
    redirectToURLWithModalState(this.props, 'mobilemenu');
  }

  handleMobileMenuClose() {
    redirectToURLWithoutModalState(this.props, 'mobilemenu');
  }

  handleMobileSearchOpen() {
    redirectToURLWithModalState(this.props, 'mobilesearch');
  }

  handleMobileSearchClose() {
    redirectToURLWithoutModalState(this.props, 'mobilesearch');
  }

  handleSubmit(values) {
    const { currentSearchParams, searchId } = this.props;
    const { history, config, routeConfiguration } = this.props;

    const topbarSearchParams = () => {
      if (isMainSearchTypeKeywords(config)) {
        return { keywords: values?.keywords };
      }
      // topbar search defaults to 'location' search
      const { search, selectedPlace } = values?.location;
      const { origin, bounds } = selectedPlace;
      const originMaybe = isOriginInUse(config) ? { origin } : {};

      return {
        ...originMaybe,
        address: search,
        bounds,
      };
    };
    const searchParams = {
      ...currentSearchParams,
      ...topbarSearchParams(),
    };
    history.push(createResourceLocatorString('SearchPage', routeConfiguration, { searchId: !!searchId ? searchId : LISTING_SEARCH_ID }, searchParams));
  }

  handleLogout() {
    const { onLogout, history, routeConfiguration } = this.props;
    onLogout().then(() => {
      const path = pathByRouteName('LandingPage', routeConfiguration);

      // In production we ensure that data is really lost,
      // but in development mode we use stored values for debugging
      if (appSettings.dev) {
        history.push(path);
      } else if (typeof window !== 'undefined') {
        window.location = path;
      }

      console.log('logged out'); // eslint-disable-line
    });
  }

  render() {
    const {
      className,
      rootClassName,
      desktopClassName,
      mobileRootClassName,
      mobileClassName,
      isAuthenticated,
      isLoggedInAs,
      authScopes,
      authInProgress,
      currentUser,
      currentUserHasListings,
      currentUserHasOrders,
      currentPage,
      notificationCount,
      intl,
      location,
      onManageDisableScrolling,
      onResendVerificationEmail,
      sendVerificationEmailInProgress,
      sendVerificationEmailError,
      showGenericError,
      config,
      routeConfiguration,
      params,
      uploadNotifications
    } = this.props;

    const authenticatedOnClientSide = isAuthenticated;
    const isAuthenticatedOrJustHydrated = isAuthenticated;

    const { mobilemenu, mobilesearch, keywords, address, origin, bounds } = parse(location.search, {
      latlng: ['origin'],
      latlngBounds: ['bounds'],
    });
    const { userType } = (currentUser && currentUser.id && currentUser.attributes.profile.publicData) || {};
    // Custom links are sorted so that group="primary" are always at the beginning of the list.
    const sortedCustomLinks = sortCustomLinks(config.topbar?.customLinks);
    const customLinks = getResolvedCustomLinks(sortedCustomLinks, routeConfiguration);
    const resolvedCurrentPage = currentPage || getResolvedCurrentPage(location, routeConfiguration);

    const notificationDot = notificationCount > 0 ? <div className={css.notificationDot} /> : null;

    const hasMatchMedia = typeof window !== 'undefined' && window?.matchMedia;
    const isMobileLayout = hasMatchMedia
      ? window.matchMedia(`(max-width: ${MAX_MOBILE_SCREEN_WIDTH}px)`)?.matches
      : true;
    const isMobileMenuOpen = isMobileLayout && mobilemenu === 'open';
    const isMobileSearchOpen = isMobileLayout && mobilesearch === 'open';

    const mobileMenu = (
      <TopbarMobileMenu
        isAuthenticated={isAuthenticated}
        currentUserHasListings={currentUserHasListings}
        currentUser={currentUser}
        onLogout={this.handleLogout}
        notificationCount={notificationCount}
        currentPage={resolvedCurrentPage}
        customLinks={customLinks}
      />
    );

    const topbarSearcInitialValues = () => {
      if (isMainSearchTypeKeywords(config)) {
        return { keywords };
      }

      // Only render current search if full place object is available in the URL params
      const locationFieldsPresent = isOriginInUse(config)
        ? address && origin && bounds
        : address && bounds;
      return {
        location: locationFieldsPresent
          ? {
            search: address,
            selectedPlace: { address, origin, bounds },
          }
          : null,
      };
    };
    const initialSearchFormValues = topbarSearcInitialValues();

    const isCreator = false;
    const isViewerLoggedIn = isAuthenticated;
    const classes = classNames(rootClassName || css.root, className, !isAuthenticated && css.headerwithoutLogin, isViewerLoggedIn && css.viewerLoggedInHeader, isCreator && css.fixedHeader);

    const profileMenuMaybe = authenticatedOnClientSide ? (
      <ProfileMenu currentPage={currentPage} currentUser={currentUser} onLogout={this.handleLogout} />
    ) : <NotSignedInMenu currentPage={currentPage} />;

    const audienceProfileMenuMaybe = authenticatedOnClientSide ? (
      <AudienceProfileMenu currentPage={currentPage} currentUser={currentUser} onLogout={this.handleLogout} />
    ) : <NotSignedInMenu currentPage={currentPage} />;


    return (
      <div className={classes}>
        <Notifications uploadNotifications={uploadNotifications} />
        <LimitedAccessBanner
          isAuthenticated={isAuthenticated}
          isLoggedInAs={isLoggedInAs}
          authScopes={authScopes}
          currentUser={currentUser}
          onLogout={this.handleLogout}
          currentPage={resolvedCurrentPage}
        />
        <div className={classNames(mobileRootClassName || css.container, mobileClassName)}>

          <LinkedLogo
            layout={'mobile'}
            alt={intl.formatMessage({ id: 'Topbar.logoIcon' })}
            linkToExternalSite={config?.topbar?.logoLink}
          />
          <div className={css.searchMenuContainer}>
            {(authenticatedOnClientSide && userType == CREATOR_USER_TYPE) ? <NamedLink name="NewListingPage" className={classNames(css.topbarLink, css.libraryLink)}>
              <span className={css.topbarLibraryLinkLabel}>
                <IconCollection icon="plus-icon" />
              </span>
            </NamedLink> : null}
            <Button
              rootClassName={css.searchMenu}
              onClick={this.handleMobileSearchOpen}
              title={intl.formatMessage({ id: 'Topbar.searchIcon' })}
            >
              <SearchIcon className={css.searchMenuIcon} />
            </Button>
            {userType == CREATOR_USER_TYPE ? profileMenuMaybe : audienceProfileMenuMaybe}
            {/* <Button
              rootClassName={css.menu}
              onClick={this.handleMobileMenuOpen}
              title={intl.formatMessage({ id: 'Topbar.menuIcon' })}
            >
              <MenuIcon className={css.menuIcon} />
              {notificationDot}
            </Button> */}
          </div>
        </div>
        <div className={css.desktop}>
          <TopbarDesktop
            className={desktopClassName}
            currentUserHasListings={currentUserHasListings}
            currentUser={currentUser}
            currentPage={resolvedCurrentPage}
            initialSearchFormValues={initialSearchFormValues}
            intl={intl}
            isAuthenticated={isAuthenticated}
            notificationCount={notificationCount}
            onLogout={this.handleLogout}
            onSearchSubmit={this.handleSubmit}
            config={config}
            customLinks={customLinks}
          />
        </div>
        <Modal
          id="TopbarMobileMenu"
          containerClassName={css.modalContainer}
          isOpen={isMobileMenuOpen}
          onClose={this.handleMobileMenuClose}
          usePortal
          onManageDisableScrolling={onManageDisableScrolling}
        >
          {authInProgress ? null : mobileMenu}
        </Modal>
        <Modal
          id="TopbarMobileSearch"
          containerClassName={css.modalContainerSearchForm}
          isOpen={isMobileSearchOpen}
          onClose={this.handleMobileSearchClose}
          usePortal
          onManageDisableScrolling={onManageDisableScrolling}
        >
          <div className={css.searchContainer}>
            <TopbarSearchForm
              onSubmit={this.handleSubmit}
              initialValues={initialSearchFormValues}
              isMobile
              appConfig={config}
            />
            <p className={css.mobileHelp}>
              <FormattedMessage id="Topbar.mobileSearchHelp" />
            </p>
          </div>
        </Modal>
        <ModalMissingInformation
          id="MissingInformationReminder"
          containerClassName={css.missingInformationModal}
          currentUser={currentUser}
          currentUserHasListings={currentUserHasListings}
          currentUserHasOrders={currentUserHasOrders}
          location={location}
          onManageDisableScrolling={onManageDisableScrolling}
          onResendVerificationEmail={onResendVerificationEmail}
          sendVerificationEmailInProgress={sendVerificationEmailInProgress}
          sendVerificationEmailError={sendVerificationEmailError}
        />

        <GenericError show={showGenericError} />
      </div>
    );
  }
}

TopbarComponent.defaultProps = {
  className: null,
  rootClassName: null,
  desktopClassName: null,
  mobileRootClassName: null,
  mobileClassName: null,
  notificationCount: 0,
  currentUser: null,
  currentUserHasOrders: null,
  currentPage: null,
  sendVerificationEmailError: null,
  authScopes: [],
};

TopbarComponent.propTypes = {
  className: string,
  rootClassName: string,
  desktopClassName: string,
  mobileRootClassName: string,
  mobileClassName: string,
  isAuthenticated: bool.isRequired,
  isLoggedInAs: bool.isRequired,
  authScopes: array,
  authInProgress: bool.isRequired,
  currentUser: propTypes.currentUser,
  currentUserHasListings: bool.isRequired,
  currentUserHasOrders: bool,
  currentPage: string,
  notificationCount: number,
  onLogout: func.isRequired,
  onManageDisableScrolling: func.isRequired,
  onResendVerificationEmail: func.isRequired,
  sendVerificationEmailInProgress: bool.isRequired,
  sendVerificationEmailError: propTypes.error,
  showGenericError: bool.isRequired,

  // These are passed from Page to keep Topbar rendering aware of location changes
  history: shape({
    push: func.isRequired,
  }).isRequired,
  location: shape({
    search: string.isRequired,
  }).isRequired,

  // from useIntl
  intl: intlShape.isRequired,

  // from useConfiguration
  config: object.isRequired,

  // from useRouteConfiguration
  routeConfiguration: arrayOf(propTypes.route).isRequired,
};

const Topbar = props => {
  const config = useConfiguration();
  const routeConfiguration = useRouteConfiguration();
  const intl = useIntl();
  const { searchId } = useParams();
  const { uploadNotifications } = useSelector(state => state.user);

  return (
    <TopbarComponent
      config={config}
      routeConfiguration={routeConfiguration}
      intl={intl}
      searchId={searchId}
      uploadNotifications={uploadNotifications}
      {...props}
    />
  );
};

export default Topbar;
